@tailwind base;

@tailwind components;

@tailwind utilities;

a {
  text-decoration: underline;
}

#download {
  text-decoration: none;
}

table {
  width: 100%;
}

td a {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

table tr:hover td {
  background: #333;
  color: #fff;
}

.bg {
  background: #59c173; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #59c173,
    #a17fe0,
    #5d26c1
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.footer li {
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.footer a,
.footer span {
  padding: 0 5px;
}
.footer li:last-child {
  border: none;
}

#image {
  display: none;
}
